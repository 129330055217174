<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">{{ eventLocal.id ? "更新" : "添加" }} 排班</h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-event');
                hide();
              "
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

          <!-- Calendar -->
          <validation-provider
              #default="validationContext"
              name="选择门诊"
              rules="required"
              v-if="!eventLocal.id"
            >

              <b-form-group
                label="选择门诊"
                label-for="calendar"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.calendar"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="calendarOptions"
                  label="label"
                  :clearable="false"
                  :reduce="calendar => calendar.label"
                  input-id="calendar"
                  @close="handleChangecalendar"
                >

                  <template #option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>

                  <template #selected-option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          <!-- Calendar -->
          <validation-provider
              #default="validationContext"
              name="选择门诊"
              rules="required"
              v-if="eventLocal.id"
            >

              <b-form-group
                label="选择门诊"
                label-for="calendar"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.calendar"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="calendarOptions"
                  label="label"
                  disabled
                  :clearable="false"
                  :reduce="calendar => calendar.label"
                  input-id="calendar"
                >

                  <template #option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>

                  <template #selected-option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          <!-- 门诊 -->
          <!-- <validation-provider
              #default="validationContext"
              name="clinicname"
              rules="required"
            >
              <b-form-group label="门诊">
                <el-select style="width: 100%;" v-model="eventLocal.extendedProps.clinicname" placeholder="请选择" id="event-clinicname">
                  <el-option
                    v-for="item in clinicoptions"
                    id="event-clinicname"
                    :key="item._id"
                    :label="item.clinicname"
                    :value="item.clinicname">
                  </el-option>
                </el-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->


            <!-- 医生 -->
          <validation-provider
              #default="validationContext"
              name="doctor"
              rules="required"
              v-if="!eventLocal.id"
            >
              <b-form-group label="选择医生">
                <el-select style="width: 100%;" v-model="eventLocal.extendedProps.doctor" placeholder="请选择" id="event-doctor" @change="selectTrigger">
                  <el-option
                    v-for="item in doctoroptions"
                    id="event-doctor"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                    >
                  </el-option>
                </el-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

              <!-- 医生 -->
          <validation-provider
              #default="validationContext"
              name="doctor"
              rules="required"
              v-if="eventLocal.id"
            >
              <b-form-group label="选择医生">
                <el-select style="width: 100%;" v-model="eventLocal.extendedProps.doctor.name" disabled placeholder="请选择" id="event-doctor" @change="selectTrigger">
                  <el-option
                    v-for="item in doctoroptions"
                    id="event-doctor"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                    >
                  </el-option>
                </el-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

              <!-- 是否完诊 -->
          <validation-provider
              #default="validationContext"
              name="endmeet"
              rules="required"
              v-if="eventLocal.id"
            >
              <b-form-group label="是否完诊">
                <el-select style="width: 100%;" v-model="eventLocal.extendedProps.endmeet" placeholder="请选择" id="event-endmeet">
                  <el-option
                    v-for="item in endmeetoption"
                    id="event-doctor"
                    :key="item._id"
                    :label="item.name"
                    :value="item.value"
                    >
                  </el-option>
                </el-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


           <!-- 挂号费 -->
           <validation-provider
              #default="validationContext"
              name="挂号费"
              rules="required"
            >
              <b-form-group
                label="挂号费"
                label-for="event-registerprice"
              >
                <b-form-input
                  id="event-registerprice"
                  v-model="eventLocal.extendedProps.registerprice"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="挂号费"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="start"
              rules="required"
            >

              <b-form-group
                label="排班日期"
                id="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="config"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


              <!-- 排班模板 -->
          <validation-provider
              #default="validationContext"
              name="timeslot"
              rules="required"
            >
              <b-form-group label="排班模板">
                <el-select v-model="eventLocal.extendedProps.slotname" placeholder="请选择" id="event-timeslot">
                  <el-option
                    v-for="item in timeslotoptions"
                    id="event-timeslot"
                    :key="item._id"
                    :label="item.slotname"
                    :value="item.slotname"
                    @click.native="changeGateway(item)"
                    >
                  </el-option>
                </el-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Event URL -->
            <el-descriptions v-for="(item, index) in eventLocal.extendedProps.slotandnum" :key="index" class="margin-top" :column="3" size="medium" border>
                <el-descriptions-item label="开始">
                  <el-time-select
                    class="eltimeselect"
                    placeholder="起始时间"
                    :clearable="false"
                    :editable="false"
                    popper-class="digoleltimeselect"
                    v-model="item.firsttime"
                    :picker-options="{
                      start: starttimestep,
                      step: timestep,
                      end: endtimestep
                    }">
                  </el-time-select>
                </el-descriptions-item>
                <el-descriptions-item label="结束">
                  <el-time-select
                    class="eltimeselect"
                    placeholder="结束时间"
                    :clearable="false"
                    :editable="false"
                    popper-class="digoleltimeselect"
                    v-model="item.endtime"
                    :picker-options="{
                      start: starttimestep,
                      step: timestep,
                      end: endtimestep,
                      minTime: item.firsttime
                    }">
                  </el-time-select>
                </el-descriptions-item>
                <el-descriptions-item label="放号"><el-input style="width: 100px;" v-model="item.meetnum" placeholder="输入放号"></el-input></el-descriptions-item>
            </el-descriptions>
            
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventLocal.id ? "更新" : "添加" }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                重置
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormSelect,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs } from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";
import { Mandarin } from 'flatpickr/dist/l10n/zh.js';

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEventHandlerSidebarActive",
    event: "update:is-event-handler-sidebar-active",
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      starttimestep:"07:00",
      endtimestep:"18:00",
      timestep:"00:15",
      required,
      email,
      url,
      clinicoptions: [],
      doctoroptions: [],
      timeslotoptions: [],
      timeslot: [],
      meetings:[],
      endmeetoption:[
        {
          value: false,
          name: '否'
        }, {
          value: true,
          name: '是'
        }
      ],
      config: {
        wrap: true,
        dateFormat:"Y-m-d",
        enableTime:false,			//选择小时分种
        enableSeconds:false,
        defaultHour: 8,			//默认8点
        time_24hr: true,			//时间24小时制
        locale: Mandarin			//中文
      },
    };
  },
  methods: {
      findIdByName(array, name) {  
        return array.find(item => item.clinicname === name)?._id;  
      },
      handleChangecalendar(e){
        const clinicnameid = this.findIdByName(this.clinicoptions, this.eventLocal.extendedProps.calendar)
        this.eventLocal.extendedProps.clinicnameid = clinicnameid
      },
      listclinic(){
        this.$api.clinic.list().then(res =>{
          this.clinicoptions = res.data.data
        })
      },
      listdoctor(){
        this.$api.sysusers.doctorlist().then(res =>{
          this.doctoroptions = res.data.data
        })  
      },
      listtimeslot(){
        this.$api.timeslot.list().then(res =>{
          this.timeslotoptions = res.data.data
        })  
      },
      changeGateway(data){
        this.eventLocal.extendedProps.slotandnum = data.slotandnum //此data是选中的那个option的完整数据item
      },
      selectTrigger(value) {
        console.log(value)
        let opt= {};
        opt= this.doctoroptions.find((item)=>{
            return item._id === value;
        });
        console.log(opt)
        this.eventLocal.title = opt.name
      }
  },
  created(){
    this.listclinic()
    this.listdoctor()
    this.listtimeslot()
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null);

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetEventLocal, props.clearEventData);

    clearFormData.value = clearForm;

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.eltimeselect{
  width: 120px!important;
}
.digoleltimeselect{
  width: 120px!important;
}
</style>
